import { notification } from 'antd';
import { union } from 'lodash';
import { apiUrlPublic, axiosCallApi } from '../../../config/config';
import actionTypes from './action.types';

// eslint-disable-next-line import/prefer-default-export
export const addToFavorites = (favorite) => ({
  type: actionTypes.CUSTOMER_ADD_TO_FAVORITES,
  favorite
});

// eslint-disable-next-line import/prefer-default-export
export const removeFavorite = (favorite) => ({
  type: actionTypes.CUSTOMER_REMOVE_FAVORITE,
  favorite
});

export const updateFavorites = (favorites) => ({
  type: actionTypes.CUSTOMER_UPDATE_FAVORITES,
  favorites
});

export const updateCart = (tempCart) => ({
  type: actionTypes.CUSTOMER_SET_CART,
  tempCart
});

export const synchronizeFavorites = (favorites) => ({
  type: actionTypes.CUSTOMER_SYNC_FAVORITES,
  favorites
});

export const clearFavorites = () => ({
  type: actionTypes.CUSTOMER_CLEAR_FAVORITES
});

export const emptyTempCart = () => ({
  type: actionTypes.CUSTOMER_EMPTY_TEMP_CART
});

export const syncFavorites = () => {
  return async (dispatch, getState) => {
    const onSuccess = (response) => {
      // console.log('RESPONSE', response);
      const { mergedFavorites } = response;

      dispatch(synchronizeFavorites(mergedFavorites));
      return response;
    };

    const onError = (error) => {
      console.log(error);
      return error;
    };

    try {
      const { authenticated } = getState().auth;
      const { favorites } = getState().customer;
      if (authenticated) {
        const fetchFavorites = await axiosCallApi.get(
          `${apiUrlPublic}/customer/favorites`
        );
        const { favorites: favs } = await fetchFavorites.data;
        const favoritesIds = favs.map((item) => item._id);
        const mergedFavorites = union(favorites, favoritesIds);
        const data = {
          favorites: mergedFavorites
        };

        await axiosCallApi.post(`${apiUrlPublic}/customer/favorites/sync`, data);
        return {
          favorites: onSuccess({ mergedFavorites })
        };
      }
      return {
        favorites: onSuccess({ favorites })
      };
    } catch (error) {
      notification.error({
        message: 'Παρουσιάστηκε κάποιο σφάλμα, παρακαλώ δοκιμάστε ξανά'
      });
      return { error: onError(error) };
    }
  };
};

export const addToFavorite = (id) => {
  return async (dispatch, getState) => {
    const onSuccess = (favorite) => {
      dispatch(addToFavorites(favorite));
      return favorite;
    };

    const onError = (error) => {
      console.log(error);
      return error;
    };

    try {
      const isAuthenticated = getState().auth.authenticated;
      if (isAuthenticated) {
        await axiosCallApi.post(`${apiUrlPublic}/customer/favorites/${id}`);
        return {
          favorite: onSuccess(id)
        };
      }
      return {
        favorite: onSuccess(id)
      };
    } catch (error) {
      notification.error({
        message:
          'Παρουσιάστηκε κάποιο σφάλμα κατά τον συγχρονισμό των αγαπημένων, δοκιμάστε ξανά',
        className: 'custom-notification'
      });
      return { error: onError(error) };
    }
  };
};

export const removeFromFavorites = (id) => {
  return async (dispatch, getState) => {
    const onSuccess = (favorite) => {
      dispatch(removeFavorite(favorite));
      return favorite;
    };

    const onError = (error) => {
      console.log(error);
      return error;
    };

    try {
      const isAuthenticated = getState().auth.authenticated;
      if (isAuthenticated) {
        await axiosCallApi.delete(`${apiUrlPublic}/customer/favorites/${id}`);
        return {
          favorite: onSuccess(id)
        };
      }
      return {
        favorite: onSuccess(id)
      };
    } catch (error) {
      notification.error({
        message: 'Παρουσιάστηκε κάποιο σφάλμα, δοκιμάστε ξανά',
        className: 'custom-notification'
      });
      return { error: onError(error) };
    }
  };
};
